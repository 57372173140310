/*!
 * --- EVENTS -----------------------------------
*/
.events
  .sectionTitle
    margin-bottom 60px
    @media xs
      margin-bottom 30px


.eventsList
  &-item
    padding-bottom 30px

    &:not(:last-child)
      margin-bottom 30px
      border-bottom 2px solid color-pink


.eventItemRow
  overflow hidden

  &-top
    display flex
    align-items center
    position relative
    transit()

  &-date
    display flex
    align-items center
    position absolute
    width 100px
    top 50%
    transform translateY(-50%)
    left 0
    transit(0.32s)
    @media md
      width 80px

    &-left
      font-size 40px
      @media md
        font-size 30px

    &-right
      font-size 14px
      margin-left 5px
      @media md
        font-size 10px

      .week
        opacity 0.5

  &-title
    flex-grow 1
    padding-left 100px
    padding-right 15px
    display flex
    align-items center
    transit(0.32s)
    @media md
      padding-left 80px
      display block
    @media sm
      padding-top 5px
      padding-right 0
      padding-bottom 5px

  &-name
    font-size 26px
    @media md
      font-size 22px
    @media sm
      position relative
      font-size 18px
      padding-right 20px
    @media xs
      font-size 16px

    .icon
      size(0,0)
      opacity 0
      visibility hidden
      fill color
      transit()
      @media sm
        size(18,14)
        opacity 1
        visibility visible
        position absolute
        top 5px
        right 0

    .sep
      transit(0.1s)
      @media md
        display none

  &-place
    margin-left 0.5em
    transit(0.1s)
    @media md
      margin-left 0
    @media sm
      font-size 12px

  &-action
    @media sm
      display none

  &-bottom
    line-height 1.4
    padding 0
    height 0
    overflow hidden
    visibility hidden
    transit()
    @media md
      font-size 14px

    &-footer
      display none
      text-align center
      @media sm
        display block
        padding-top 15px

  &.is-active &-bottom
    padding-top 30px
    height auto
    overflow auto
    visibility visible
    @media md
      padding-top 15px

  &-top:hover &,
  &.is-active &-top &
    &-date
      opacity 0
      left -90px
      @media sm
        left 0
        opacity 1

    &-title
      padding-left 0
      @media sm
        padding-left 80px

      &:hover
        color color-pink
        cursor pointer

    &-name
      .sep
        opacity 0

      .icon
        size(18,14)
        opacity 1
        visibility visible

    &-title:hover .icon
        fill color-pink

    &-place
      opacity 0
      @media sm
        opacity 1

  &.is-active &-name .icon
    transform rotateX(180deg)
