/*!
 --- HELPERS ------------------------------------
*/

.hide
  display none


.img
  display block
  max-width 100%


.iframe-container
  overflow: hidden
  padding-top: 56.25%
  position: relative
  width 100%

  iframe
    border: 0
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%


.nolaptop
  @media laptop
    display none !important


.notablet
  @media tablet
    display none !important


.nomobile
  @media table
    display none !important
