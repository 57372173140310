/*!
 --- BASE ---------------------------------------
*/

*
  box-sizing: border-box
  margin: 0
  padding: 0

  &:before, &:after
    box-sizing: border-box

body, html
  padding: 0
  border: 0
  width: 100%
  min-height 100vh

html
  font-size: font16
  line-height normal
  overflow-x hidden

body
  font-family: font
  color: color
  font-weight: normal
  background-color: #fff
  display: flex
  overflow hidden

.browsehappy
  display: block
  margin: 0
  padding: 30px
  text-align: center
  background: #e55454
  color: #fff
  font-size: 18px
  line-height: 1.4

  a,
  a:hover
    color: #fff
    text-decoration: underline

.container
  width: 100%
  max-width: 1170px
  padding: 0 15px
  margin: 0 auto

  &--980
    max-width 980px

  &--860
    max-width 860px


/*!
 --- FOOTER TO BOTTOM ---------------------------
*/
.wrapper
  flex 1 1 auto
  width 100%
  display flex
  flex-flow column nowrap

.main
  flex 1 1 auto
  min-height 1vh

.header, .footer
  flex-shrink 0


/*!
 --- UI ELEMENTS --------------------------------
*/

a
  font-size inherit
  font-family inherit
  text-decoration none
  color inherit
  transit()

  &.link:hover
    opacity 0.6

  &.link-color
    &:hover
      color color-pink


strong, b
  font-weight bold

// a[href]:not([class])

button, input, select, textarea
  font-family inherit
  font-size inherit
  color inherit
  display block
  width auto
  outline none
  line-height 1
  border 0
  background-color rgba(255,255,255,0)
  transit()


button,
button[type="submit"],
input[type="submit"]
  cursor pointer


button[type='submit']:disabled,
input[type='submit']:disabled
  cursor not-allowed


input[type="search"],
input[type=number]
  -webkit-appearance textfield


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance none
  margin 0


textarea
  width 100%
  resize none


fieldset
  border none
  margin 0
  padding 0


.select
  select
    appearance none
    &::-ms-expand
      display none


h1, h2, h3, h4, h5, h6
  margin 0
