/*!
 * --- HERO -------------------------------------
*/
.hero

  &-body
    background-color #ccc
    bg(bottom center,cover)
    background-image url('../images/hero.webp')
    height 90vh
    max-height 600px
    display flex
    flex-direction column
    justify-content flex-end
    @media sm
      max-height 300px

  &-title
    color #0E2973
    text-transform uppercase
    font-size 43px
    font-weight bold
    text-align center
    line-height 1.2
    padding 0 15px 30px
    @media xl
      font-size 34px
    @media lg
      font-size 28px
    @media sm
      font-size 26px
    @media xs
      font-size 20px
      padding-bottom 15px

    h1
      font-size inherit
      font-weight inherit


.heroAbout
  margin 0

  &-item
    padding 0


.heroAboutItem
  text-align center
  color #fff
  padding 40px 15px
  height 100%
  display flex
  align-items center
  justify-content center
  @media md
    padding 30px 15px
  @media sm
    padding 20px 5px

  &-body
    width 100%

  &-title
    display inline-block
    font-family font-montserrat
    font-size 40px
    font-weight 500
    margin-bottom 10px
    @media lg
      font-size 28px
    @media sm
      font-size 18px

  &-txt
    font-size 18px
    font-weight 300
    @media lg
      font-size 14px
