/*!
 --- FONTS --------------------------------------
*/
/*! --- Oswald --- */
@font-face {
  font-family: 'Oswald';
  src: local('Oswald Bold'), local('Oswald-Bold'),
    url('../fonts/hinted-Oswald-Bold.woff2') format('woff2'),
    url('../fonts/hinted-Oswald-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/*! --- Avenir Next Cyr --- */
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Light'),
    url('../fonts/hinted-AvenirNextCyr-Light.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Italic'),
    url('../fonts/hinted-AvenirNextCyr-Italic.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-MediumItalic'),
    url('../fonts/hinted-AvenirNextCyr-MediumItalic.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Regular'),
    url('../fonts/hinted-AvenirNextCyr-Regular.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-BoldItalic'),
    url('../fonts/hinted-AvenirNextCyr-BoldItalic.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Medium'),
    url('../fonts/hinted-AvenirNextCyr-Medium.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Bold'),
    url('../fonts/hinted-AvenirNextCyr-Bold.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-LightItalic'),
    url('../fonts/hinted-AvenirNextCyr-LightItalic.woff2') format('woff2'),
    url('../fonts/hinted-AvenirNextCyr-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

/*! --- MONTSERRAT --- */
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
    url('../fonts/hinted-Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../fonts/hinted-Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
    url('../fonts/hinted-Montserrat-BoldItalic.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url('../fonts/hinted-Montserrat-LightItalic.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url('../fonts/hinted-Montserrat-Italic.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
    url('../fonts/hinted-Montserrat-MediumItalic.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../fonts/hinted-Montserrat-Light.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../fonts/hinted-Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/hinted-Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/hinted-Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/hinted-Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
