textOverflow($lines)
  display box
  display -ms-box
  display -webkit-box
  display -moz-box
  text-overflow ellipsis
  overflow hidden
  -webkit-line-clamp $lines
  box-orient vertical
  -moz-box-orient vertical
  -webkit-box-orient vertical
  -ms-box-orient vertical


transit($duration = 0.25s)
  transition all $duration linear


hidden()
  opacity 0
  visibility hidden


visible()
  opacity 1
  visibility visible


pseudo()
  content ''
  display block
  position absolute


bg($position, $size)
  background-repeat no-repeat
  background-position $position
  background-size $size


centered-img()
  position relative

  img
    width: 100%
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50)


resetlist()
  margin 0
  padding 0
  list-style-type none


size($wsize, $hsize)
  width unit($wsize, 'px')
  height unit($hsize, 'px')
