/*!
 * --- PAGE CONTACTS ----------------------------
*/
.contactsForm
  margin-top 2em

  .fgroup
    margin-bottom 2em
    @media xs
      margin-bottom 1em

  &-button
    text-align center


.contactsMap
  height 450px
  background-color #eee
  @media sm
    height 380px
