/*!
 --- HEADER -------------------------------------
*/

.header
  &-body
    background-color: rgba(#000, 0.8)
    color #fff
    position fixed
    top 0
    left 0
    z-index 4
    width 100%
    padding 0 300px 0 200px
    text-align center
    height 70px
    display flex
    align-items center
    @media md
      height 50px
    @media sm
      height auto
      display block
      padding 5px 15px
    @media xs
      padding 5px 60px

  &-logo
    position absolute
    top 50%
    left 15px
    transform translateY(-50%)
    @media sm
      position static
      transform none
      padding-bottom 5px

  &-menu
    width 100%

  &-button
    position absolute
    top 0
    right 0
    height 100%
    width 100%
    max-width 300px
    @media xl
      width auto
      max-width none
    @media md
      right auto
      left 50%
      transform translateX(-50%)
    @media sm
      position static
      transform none
      max-width 300px
      margin 0 auto


.headerLogo
  display inline-flex
  align-items center
  font-family font-oswald
  font-size 28px
  font-weight bold
  text-transform uppercase
  @media xl
    font-size 20px

  &-pic
    flex none
    width 25px
    height auto
    margin-right 15px
    @media sm
      width 15px


.mainmenu
  resetlist()
  display flex
  flex-wrap wrap
  justify-content center
  @media xl
    font-size 14px
  @media md
    position fixed
    top 0
    right -100%
    width 320px
    height 100%
    z-index 4
    background-color rgba(#000, 0.9)
    display block
    padding-top 60px
    transit(0.32s)
  @media sm
    font-size 16px
  @media xs
    width 100%

  &.is-active
    right 0

  li,
  &-item
    padding 0 15px
    @media xl
      padding 0 8px
    @media lg
      padding-top 5px
      padding-bottom 5px
    @media md
      padding 10px 15px

  a,
  &-link
    &:hover
      color color-pink


.headerButton
  display flex
  align-items center
  justify-content center
  height 70px
  width 100%
  background-color color-pink
  padding 0 15px
  font-family font-montserrat
  font-size 20px
  font-weight bold
  color #fff
  @media xl
    font-size 18px
  @media md
    height 50px
  @media sm
    font-size 14px
    height auto
    padding 10px 15px
  @media xs
    font-size 12px


.burger
  display block
  width 24px
  height 14px
  position absolute
  top 50%
  right 15px
  transform translateY(-50%)
  display none
  z-index 5
  @media md
    display block

  span
    display block
    width 100%
    height 2px
    background-color #fff
    position absolute
    left 0
    transit()

    &:first-child
      top 0

    &:last-child
      bottom 0

  &.is-active
    span
      top 50%
      bottom auto
      margin-top -1px

      &:first-child
        transform rotate(45deg)

      &:last-child
        transform rotate(-45deg)
