//- Colors
color = #333333
color-pink = rgba(236, 94, 135, 1)
color-blue = rgba(33, 62, 140, 1)

//- Base
font16 = 16px
font14 = 14px
font12 = 12px
font = 'Avenir Next Cyr', sans
font-oswald = 'Oswald', sans
font-montserrat = 'Montserrat', 'Avenir Next Cyr', sans

//- Breakpoints
xs = 'all and (max-width: 543px)'
sm = 'all and (max-width: 767px)'
md = 'all and (max-width: 991px)'
lg = 'all and (max-width: 1199px)'
xl = 'all and (max-width: 1399px)'

laptop = 'all and (max-width: 1199px) and (min-width: 992px)'
tablet = 'all and (max-width: 991px) and (min-width: 768px)'
mobile = 'all and (max-width: 767px)'

/*!
  BREAKPOINTS

  lg: all and (max-width: 1199px)
  md: all and (max-width: 991px)
  sm: all and (max-width: 767px)
  xs: all and (max-width: 543px)

  laptop: all and (max-width: 1199px) and (min-width: 992px)
  tablet: all and (max-width: 991px) and (min-width: 768px)
  mobile: all and (max-width: 767px)
*/
