/*!
 * --- NEWS -------------------------------------
*/
.news
  &-item
    padding-bottom 30px


.newsCard
  border 1px solid rgba(204,204,204,0.7)
  height 100%
  display flex
  flex-direction column

  &-top
    flex-grow 1

  &-pic
    height 165px
    bg(center,cover)

  &-body
    padding 24px

  &-title
    font-family font-montserrat
    font-size 18px
    line-height 1.4
    @media md
      font-size 16px
    @media xs
      font-size 14px

  &-footer
    padding 0 24px 24px
