/*!
 * --- HELP ACTION ------------------------------
*/
.helpAction
  margin 0

  &-item
    padding 0

  &-body
    height 100%
    background-color color-blue
    color #fff
    text-align center
    padding 50px 15px

  &-title
    font-size 42px
    font-family font-montserrat
    margin-bottom 30px
    @media md
      font-size 32px
    @media sm
      font-size 24px

  &-subtitle
    font-size 28px
    font-weight bold
    font-family font-montserrat
    margin-bottom 15px
    @media md
      font-size 22px
    @media sm
      font-size 18px

  &-pic
    height 100%
    bg(center,cover)
    @media xs
      display none


.helpActionFull
  padding 50px 0
  text-align center
  background-color color-blue
  color #fff

  &-title
    font-size 34px
    margin-bottom 30px
    @media sm
      font-size 26px
