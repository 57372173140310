/*
  BREAKPOINTS

  lg: all and (max-width: 1199px)
  md: all and (max-width: 991px)
  sm: all and (max-width: 767px)
  xs: all and (max-width: 543px)

  laptop: all and (max-width: 1199px) and (min-width: 992px)
  tablet: all and (max-width: 991px) and (min-width: 768px)
  mobile: all and (max-width: 767px)
*/
/*
 --- FONTS --------------------------------------
*/
/* --- Oswald --- */
@font-face {
  font-family: 'Oswald';
  src: local('Oswald Bold'), local('Oswald-Bold'), url("../fonts/hinted-Oswald-Bold.woff2") format('woff2'), url("../fonts/hinted-Oswald-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
/* --- Avenir Next Cyr --- */
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Light'), url("../fonts/hinted-AvenirNextCyr-Light.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Italic'), url("../fonts/hinted-AvenirNextCyr-Italic.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-Italic.woff") format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-MediumItalic'), url("../fonts/hinted-AvenirNextCyr-MediumItalic.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-MediumItalic.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Regular'), url("../fonts/hinted-AvenirNextCyr-Regular.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-BoldItalic'), url("../fonts/hinted-AvenirNextCyr-BoldItalic.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-BoldItalic.woff") format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Medium'), url("../fonts/hinted-AvenirNextCyr-Medium.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-Bold'), url("../fonts/hinted-AvenirNextCyr-Bold.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Next Cyr';
  src: local('AvenirNextCyr-LightItalic'), url("../fonts/hinted-AvenirNextCyr-LightItalic.woff2") format('woff2'), url("../fonts/hinted-AvenirNextCyr-LightItalic.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}
/* --- MONTSERRAT --- */
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'), url("../fonts/hinted-Montserrat-SemiBoldItalic.woff2") format('woff2'), url("../fonts/hinted-Montserrat-SemiBoldItalic.woff") format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url("../fonts/hinted-Montserrat-Medium.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url("../fonts/hinted-Montserrat-BoldItalic.woff2") format('woff2'), url("../fonts/hinted-Montserrat-BoldItalic.woff") format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url("../fonts/hinted-Montserrat-LightItalic.woff2") format('woff2'), url("../fonts/hinted-Montserrat-LightItalic.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url("../fonts/hinted-Montserrat-Italic.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Italic.woff") format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'), url("../fonts/hinted-Montserrat-MediumItalic.woff2") format('woff2'), url("../fonts/hinted-Montserrat-MediumItalic.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'), url("../fonts/hinted-Montserrat-Light.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("../fonts/hinted-Montserrat-SemiBold.woff2") format('woff2'), url("../fonts/hinted-Montserrat-SemiBold.woff") format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url("../fonts/hinted-Montserrat-Bold.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url("../fonts/hinted-Montserrat-Regular.woff2") format('woff2'), url("../fonts/hinted-Montserrat-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
/*
 --- GRID ---------------------------------------
*/
.rw {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -15px;
}
.cl {
  box-sizing: border-box;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}
.cl-1 {
  max-width: 8.333333%;
}
.cl-2 {
  max-width: 16.666666%;
}
.cl-3 {
  max-width: 25%;
}
.cl-4 {
  max-width: 33.333333%;
}
.cl-5 {
  max-width: 41.666666%;
}
.cl-6 {
  max-width: 50%;
}
.cl-7 {
  max-width: 58.333333%;
}
.cl-8 {
  max-width: 66.666666%;
}
.cl-9 {
  max-width: 75%;
}
.cl-10 {
  max-width: 83.333333%;
}
.cl-11 {
  max-width: 91.666666%;
}
.cl-12 {
  max-width: 100%;
}
@media all and (max-width: 1199px) {
  .cl-lg-1 {
    max-width: 8.333333%;
  }
  .cl-lg-2 {
    max-width: 16.666666%;
  }
  .cl-lg-3 {
    max-width: 25%;
  }
  .cl-lg-4 {
    max-width: 33.333333%;
  }
  .cl-lg-5 {
    max-width: 41.666666%;
  }
  .cl-lg-6 {
    max-width: 50%;
  }
  .cl-lg-7 {
    max-width: 58.333333%;
  }
  .cl-lg-8 {
    max-width: 66.666666%;
  }
  .cl-lg-9 {
    max-width: 75%;
  }
  .cl-lg-10 {
    max-width: 83.333333%;
  }
  .cl-lg-11 {
    max-width: 91.666666%;
  }
  .cl-lg-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 991px) {
  .cl-md-1 {
    max-width: 8.333333%;
  }
  .cl-md-2 {
    max-width: 16.666666%;
  }
  .cl-md-3 {
    max-width: 25%;
  }
  .cl-md-4 {
    max-width: 33.333333%;
  }
  .cl-md-5 {
    max-width: 41.666666%;
  }
  .cl-md-6 {
    max-width: 50%;
  }
  .cl-md-7 {
    max-width: 58.333333%;
  }
  .cl-md-8 {
    max-width: 66.666666%;
  }
  .cl-md-9 {
    max-width: 75%;
  }
  .cl-md-10 {
    max-width: 83.333333%;
  }
  .cl-md-11 {
    max-width: 91.666666%;
  }
  .cl-md-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .cl-sm-1 {
    max-width: 8.333333%;
  }
  .cl-sm-2 {
    max-width: 16.666666%;
  }
  .cl-sm-3 {
    max-width: 25%;
  }
  .cl-sm-4 {
    max-width: 33.333333%;
  }
  .cl-sm-5 {
    max-width: 41.666666%;
  }
  .cl-sm-6 {
    max-width: 50%;
  }
  .cl-sm-7 {
    max-width: 58.333333%;
  }
  .cl-sm-8 {
    max-width: 66.666666%;
  }
  .cl-sm-9 {
    max-width: 75%;
  }
  .cl-sm-10 {
    max-width: 83.333333%;
  }
  .cl-sm-11 {
    max-width: 91.666666%;
  }
  .cl-sm-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 543px) {
  .cl-xs-1 {
    max-width: 8.333333%;
  }
  .cl-xs-2 {
    max-width: 16.666666%;
  }
  .cl-xs-3 {
    max-width: 25%;
  }
  .cl-xs-4 {
    max-width: 33.333333%;
  }
  .cl-xs-5 {
    max-width: 41.666666%;
  }
  .cl-xs-6 {
    max-width: 50%;
  }
  .cl-xs-7 {
    max-width: 58.333333%;
  }
  .cl-xs-8 {
    max-width: 66.666666%;
  }
  .cl-xs-9 {
    max-width: 75%;
  }
  .cl-xs-10 {
    max-width: 83.333333%;
  }
  .cl-xs-11 {
    max-width: 91.666666%;
  }
  .cl-xs-12 {
    max-width: 100%;
  }
}
/*
 --- BASE ---------------------------------------
*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*:before,
*:after {
  box-sizing: border-box;
}
body,
html {
  padding: 0;
  border: 0;
  width: 100%;
  min-height: 100vh;
}
html {
  font-size: 16px;
  line-height: normal;
  overflow-x: hidden;
}
body {
  font-family: 'Avenir Next Cyr', sans;
  color: #333;
  font-weight: normal;
  background-color: #fff;
  display: flex;
  overflow: hidden;
}
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #e55454;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.browsehappy a,
.browsehappy a:hover {
  color: #fff;
  text-decoration: underline;
}
.container {
  width: 100%;
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}
.container--980 {
  max-width: 980px;
}
.container--860 {
  max-width: 860px;
}
/*
 --- FOOTER TO BOTTOM ---------------------------
*/
.wrapper {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.main {
  flex: 1 1 auto;
  min-height: 1vh;
}
.header,
.footer {
  flex-shrink: 0;
}
/*
 --- UI ELEMENTS --------------------------------
*/
a {
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: all 0.25s linear;
}
a.link:hover {
  opacity: 0.6;
}
a.link-color:hover {
  color: #ec5e87;
}
strong,
b {
  font-weight: bold;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  display: block;
  width: auto;
  outline: none;
  line-height: 1;
  border: 0;
  background-color: rgba(255,255,255,0);
  transition: all 0.25s linear;
}
button,
button[type="submit"],
input[type="submit"] {
  cursor: pointer;
}
button[type='submit']:disabled,
input[type='submit']:disabled {
  cursor: not-allowed;
}
input[type="search"],
input[type=number] {
  -webkit-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
textarea {
  width: 100%;
  resize: none;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.select select {
  appearance: none;
}
.select select::-ms-expand {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
/*
 --- HELPERS ------------------------------------
*/
.hide {
  display: none;
}
.img {
  display: block;
  max-width: 100%;
}
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media all and (max-width: 1199px) and (min-width: 992px) {
  .nolaptop {
    display: none !important;
  }
}
@media all and (max-width: 991px) and (min-width: 768px) {
  .notablet {
    display: none !important;
  }
}
@media table {
  .nomobile {
    display: none !important;
  }
}
/*
 --- UI -----------------------------------------
*/
.section {
  padding: 30px 0;
}
.section--p50 {
  padding: 50px 0;
}
.section-footer {
  text-align: center;
  padding-bottom: 15px;
}
@media all and (max-width: 543px) {
  .section-footer {
    padding-bottom: 0;
  }
}
.sectionTitle {
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  font-size: 46px;
  text-align: center;
  margin-bottom: 30px;
}
@media all and (max-width: 991px) {
  .sectionTitle {
    font-size: 36px;
  }
}
@media all and (max-width: 543px) {
  .sectionTitle {
    font-size: 26px;
  }
}
.views {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
}
.views .icon {
  width: 18px;
  height: 18px;
  fill: #333;
  margin-right: 6px;
}
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  background-color: #ec5e87;
  color: #fff;
  text-align: center;
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  padding: 5px 25px;
}
@media all and (max-width: 767px) {
  .button {
    font-size: 14px;
  }
}
.button--block {
  display: flex;
  width: 100%;
}
.button--slim {
  height: 38px;
}
.button:hover {
  background-color: #e94071;
}
.fgroup {
  position: relative;
}
.fgroup--w-icon .input {
  padding-left: 35px;
}
.fgroup--w-icon .icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  fill: #ec5e87;
}
.fgroup .error-message {
  position: absolute;
  bottom: 2px;
  right: 2px;
}
.error-message {
  font-size: 12px;
  color: #ec5e87;
}
.input {
  width: 100%;
  border-bottom: 1px solid #333;
  height: 34px;
}
.input--ta {
  height: 130px;
}
.text {
  line-height: 1.4;
}
.text p:not(:last-child) {
  margin-bottom: 1em;
}
.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  color: #ec5e87;
}
.text h1:not(:last-child),
.text h2:not(:last-child),
.text h3:not(:last-child),
.text h4:not(:last-child),
.text h5:not(:last-child),
.text h6:not(:last-child) {
  margin-bottom: 1.4em;
}
.text ul,
.text ol {
  margin-left: 1em;
}
.text ul li:not(:last-child),
.text ol li:not(:last-child) {
  margin-bottom: 1em;
}
.text-center {
  text-align: center;
}
.gallery .img {
  width: 100%;
  max-height: 600px;
}
.centeredSlider {
  position: relative;
}
.centeredSlider .swiper-slide {
  width: auto;
}
.centeredSlider .slide {
  max-height: 580px;
}
.centeredSlider .slide img {
  height: 580px;
  margin: 0 auto;
}
@media all and (max-width: 1199px) {
  .centeredSlider .slide img {
    height: 480px;
  }
}
@media all and (max-width: 991px) {
  .centeredSlider .slide img {
    height: 380px;
  }
}
@media all and (max-width: 767px) {
  .centeredSlider .slide img {
    height: 280px;
  }
}
.centeredSlider .slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.centeredSlider .slider-button.prev {
  left: 15px;
}
@media all and (max-width: 543px) {
  .centeredSlider .slider-button.prev {
    left: 5px;
  }
}
.centeredSlider .slider-button.next {
  right: 15px;
}
@media all and (max-width: 543px) {
  .centeredSlider .slider-button.next {
    right: 5px;
  }
}
.slider-button {
  display: block;
  width: 48px;
  height: 48px;
}
@media all and (max-width: 543px) {
  .slider-button {
    width: 38px;
    height: 38px;
  }
}
.slider-button:hover {
  opacity: 0.6;
}
.slider-button .icon {
  width: 42px;
  height: 42px;
  fill: #213e8c;
}
@media all and (max-width: 543px) {
  .slider-button .icon {
    width: 32px;
    height: 32px;
  }
}
.slider-button.prev .icon {
  transform: rotateY(180deg);
}
@media all and (max-width: 767px) {
  .sectionHalf {
    padding: 0 15px;
  }
}
.sectionHalf-pic {
  height: 100%;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}
@media all and (max-width: 767px) {
  .sectionHalf-pic {
    display: none;
  }
}
.sectionHalf-txt {
  padding: 2em 0 2em 15px;
  max-width: 600px;
  margin: 0 auto;
}
@media all and (max-width: 767px) {
  .sectionHalf-txt {
    padding-left: 0;
  }
}
.sectionHalf-txt .text {
  font-size: 18px;
}
@media all and (max-width: 1199px) {
  .sectionHalf-txt .text {
    font-size: 16px;
  }
}
.sectionHalf-txt .text h1,
.sectionHalf-txt .text h2,
.sectionHalf-txt .text h3,
.sectionHalf-txt .text h4,
.sectionHalf-txt .text h5,
.sectionHalf-txt .text h6 {
  font-size: 1.8em;
  text-align: center;
  color: #333;
  margin-bottom: 0.5em;
}
@media all and (max-width: 767px) {
  .sectionHalf-txt .text h1,
  .sectionHalf-txt .text h2,
  .sectionHalf-txt .text h3,
  .sectionHalf-txt .text h4,
  .sectionHalf-txt .text h5,
  .sectionHalf-txt .text h6 {
    font-size: 1.6em;
  }
}
.sectionHalf-txt .text ul li:not(:last-child),
.sectionHalf-txt .text ol li:not(:last-child) {
  margin-bottom: 0.5em;
}
/*
 --- HEADER -------------------------------------
*/
.header-body {
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  padding: 0 300px 0 200px;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 991px) {
  .header-body {
    height: 50px;
  }
}
@media all and (max-width: 767px) {
  .header-body {
    height: auto;
    display: block;
    padding: 5px 15px;
  }
}
@media all and (max-width: 543px) {
  .header-body {
    padding: 5px 60px;
  }
}
.header-logo {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  .header-logo {
    position: static;
    transform: none;
    padding-bottom: 5px;
  }
}
.header-menu {
  width: 100%;
}
.header-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 300px;
}
@media all and (max-width: 1399px) {
  .header-button {
    width: auto;
    max-width: none;
  }
}
@media all and (max-width: 991px) {
  .header-button {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media all and (max-width: 767px) {
  .header-button {
    position: static;
    transform: none;
    max-width: 300px;
    margin: 0 auto;
  }
}
.headerLogo {
  display: inline-flex;
  align-items: center;
  font-family: 'Oswald', sans;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}
@media all and (max-width: 1399px) {
  .headerLogo {
    font-size: 20px;
  }
}
.headerLogo-pic {
  flex: none;
  width: 25px;
  height: auto;
  margin-right: 15px;
}
@media all and (max-width: 767px) {
  .headerLogo-pic {
    width: 15px;
  }
}
.mainmenu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media all and (max-width: 1399px) {
  .mainmenu {
    font-size: 14px;
  }
}
@media all and (max-width: 991px) {
  .mainmenu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 320px;
    height: 100%;
    z-index: 4;
    background-color: rgba(0,0,0,0.9);
    display: block;
    padding-top: 60px;
    transition: all 0.32s linear;
  }
}
@media all and (max-width: 767px) {
  .mainmenu {
    font-size: 16px;
  }
}
@media all and (max-width: 543px) {
  .mainmenu {
    width: 100%;
  }
}
.mainmenu.is-active {
  right: 0;
}
.mainmenu li,
.mainmenu-item {
  padding: 0 15px;
}
@media all and (max-width: 1399px) {
  .mainmenu li,
  .mainmenu-item {
    padding: 0 8px;
  }
}
@media all and (max-width: 1199px) {
  .mainmenu li,
  .mainmenu-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@media all and (max-width: 991px) {
  .mainmenu li,
  .mainmenu-item {
    padding: 10px 15px;
  }
}
.mainmenu a:hover,
.mainmenu-link:hover {
  color: #ec5e87;
}
.headerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  background-color: #ec5e87;
  padding: 0 15px;
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media all and (max-width: 1399px) {
  .headerButton {
    font-size: 18px;
  }
}
@media all and (max-width: 991px) {
  .headerButton {
    height: 50px;
  }
}
@media all and (max-width: 767px) {
  .headerButton {
    font-size: 14px;
    height: auto;
    padding: 10px 15px;
  }
}
@media all and (max-width: 543px) {
  .headerButton {
    font-size: 12px;
  }
}
.burger {
  display: block;
  width: 24px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: none;
  z-index: 5;
}
@media all and (max-width: 991px) {
  .burger {
    display: block;
  }
}
.burger span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  transition: all 0.25s linear;
}
.burger span:first-child {
  top: 0;
}
.burger span:last-child {
  bottom: 0;
}
.burger.is-active span {
  top: 50%;
  bottom: auto;
  margin-top: -1px;
}
.burger.is-active span:first-child {
  transform: rotate(45deg);
}
.burger.is-active span:last-child {
  transform: rotate(-45deg);
}
/*
 --- FOOTER -------------------------------------
*/
.footer-top {
  padding: 50px 15px;
}
@media all and (max-width: 991px) {
  .footer-item:not(:last-child) {
    margin-bottom: 50px;
  }
}
.footer-right {
  text-align: center;
}
.footer-bottom {
  padding: 10px 0;
  background-color: #213e8c;
  color: #fff;
  text-align: center;
}
.fTitle {
  font-size: 20px;
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  margin-bottom: 15px;
}
.fInfo {
  max-width: 300px;
  margin: 0 auto;
}
@media all and (max-width: 991px) {
  .fInfo {
    max-width: 100%;
  }
}
.fInfo-title {
  text-transform: uppercase;
}
.fInfo-txt {
  font-size: 15px;
  line-height: 1.4;
}
.fInfo-data {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 30px;
  font-size: 15px;
}
.fInfo-data li:not(:last-child) {
  margin-bottom: 8px;
}
.subscribeForm {
  max-width: 300px;
  margin: 0 auto;
}
.subscribeForm .fgroup:not(:last-child) {
  margin-bottom: 15px;
}
.fCopyright {
  font-size: 15px;
}
@media all and (max-width: 991px) {
  .fCopyright {
    font-size: 14px;
  }
}
@media all and (max-width: 543px) {
  .fCopyright {
    font-size: 12px;
  }
}
@media all and (max-width: 543px) {
  .fCopyright span {
    display: block;
    margin: 10px 0;
  }
}
.fCopyright .sep {
  display: inline-block;
  margin: 0 0.5em;
}
@media all and (max-width: 543px) {
  .fCopyright .sep {
    display: none;
  }
}
.fSitemap {
  display: inline-block;
  text-align: left;
}
.fSitemap-title {
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  font-size: 18px;
  margin-bottom: 15px;
}
@media all and (max-width: 991px) {
  .fSitemap-title {
    display: none;
  }
}
.fSitemap-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media all and (max-width: 991px) {
  .fSitemap-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media all and (max-width: 543px) {
  .fSitemap-list {
    display: block;
    text-align: center;
  }
}
.fSitemap-list li:not(:last-child) {
  margin-bottom: 15px;
}
@media all and (max-width: 991px) {
  .fSitemap-list li:not(:last-child) {
    margin-bottom: 0;
  }
}
@media all and (max-width: 991px) {
  .fSitemap-list li {
    margin: 0 15px;
  }
}
@media all and (max-width: 543px) {
  .fSitemap-list li {
    padding: 10px 0;
    margin: 0 5px;
  }
}
/*
 * --- PAGE HEADER ------------------------------
*/
.pageHeader {
  background-color: #213e8c;
  color: #fff;
  text-align: center;
  padding: 120px 0 50px;
}
@media all and (max-width: 991px) {
  .pageHeader {
    padding-top: 100px;
  }
}
@media all and (max-width: 767px) {
  .pageHeader {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}
@media all and (max-width: 543px) {
  .pageHeader {
    padding-top: 110px;
    padding-bottom: 30px;
  }
}
@media all and (max-width: 543px) {
  .pageHeader-title {
    font-size: 1.6em;
  }
}
/*
 * --- HERO -------------------------------------
*/
.hero-body {
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  background-image: url("../images/hero.webp");
  height: 90vh;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media all and (max-width: 767px) {
  .hero-body {
    max-height: 300px;
  }
}
.hero-title {
  color: #0e2973;
  text-transform: uppercase;
  font-size: 43px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  padding: 0 15px 30px;
}
@media all and (max-width: 1399px) {
  .hero-title {
    font-size: 34px;
  }
}
@media all and (max-width: 1199px) {
  .hero-title {
    font-size: 28px;
  }
}
@media all and (max-width: 767px) {
  .hero-title {
    font-size: 26px;
  }
}
@media all and (max-width: 543px) {
  .hero-title {
    font-size: 20px;
    padding-bottom: 15px;
  }
}
.hero-title h1 {
  font-size: inherit;
  font-weight: inherit;
}
.heroAbout {
  margin: 0;
}
.heroAbout-item {
  padding: 0;
}
.heroAboutItem {
  text-align: center;
  color: #fff;
  padding: 40px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 991px) {
  .heroAboutItem {
    padding: 30px 15px;
  }
}
@media all and (max-width: 767px) {
  .heroAboutItem {
    padding: 20px 5px;
  }
}
.heroAboutItem-body {
  width: 100%;
}
.heroAboutItem-title {
  display: inline-block;
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
}
@media all and (max-width: 1199px) {
  .heroAboutItem-title {
    font-size: 28px;
  }
}
@media all and (max-width: 767px) {
  .heroAboutItem-title {
    font-size: 18px;
  }
}
.heroAboutItem-txt {
  font-size: 18px;
  font-weight: 300;
}
@media all and (max-width: 1199px) {
  .heroAboutItem-txt {
    font-size: 14px;
  }
}
/*
 * --- NEWS -------------------------------------
*/
.news-item {
  padding-bottom: 30px;
}
.newsCard {
  border: 1px solid rgba(204,204,204,0.7);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.newsCard-top {
  flex-grow: 1;
}
.newsCard-pic {
  height: 165px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.newsCard-body {
  padding: 24px;
}
.newsCard-title {
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  font-size: 18px;
  line-height: 1.4;
}
@media all and (max-width: 991px) {
  .newsCard-title {
    font-size: 16px;
  }
}
@media all and (max-width: 543px) {
  .newsCard-title {
    font-size: 14px;
  }
}
.newsCard-footer {
  padding: 0 24px 24px;
}
/*
 * --- QUOTE ------------------------------------
*/
.quote {
  background-color: #213e8c;
  color: #fff;
  text-align: center;
  padding: 50px 0;
}
@media all and (max-width: 767px) {
  .quote {
    padding: 30px 0;
  }
}
.quote--pink {
  background-color: #ec5e87;
}
.quote-body {
  max-width: 980px;
}
.quote-txt {
  font-style: italic;
  font-size: 34px;
  margin-bottom: 10px;
}
@media all and (max-width: 991px) {
  .quote-txt {
    font-size: 24px;
  }
}
@media all and (max-width: 543px) {
  .quote-txt {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.quote-author {
  font-size: 18px;
}
@media all and (max-width: 991px) {
  .quote-author {
    font-size: 14px;
  }
}
@media all and (max-width: 543px) {
  .quote-author {
    font-size: 12px;
  }
}
/*
 * --- EVENTS -----------------------------------
*/
.events .sectionTitle {
  margin-bottom: 60px;
}
@media all and (max-width: 543px) {
  .events .sectionTitle {
    margin-bottom: 30px;
  }
}
.eventsList-item {
  padding-bottom: 30px;
}
.eventsList-item:not(:last-child) {
  margin-bottom: 30px;
  border-bottom: 2px solid #ec5e87;
}
.eventItemRow {
  overflow: hidden;
}
.eventItemRow-top {
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.25s linear;
}
.eventItemRow-date {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  transition: all 0.32s linear;
}
@media all and (max-width: 991px) {
  .eventItemRow-date {
    width: 80px;
  }
}
.eventItemRow-date-left {
  font-size: 40px;
}
@media all and (max-width: 991px) {
  .eventItemRow-date-left {
    font-size: 30px;
  }
}
.eventItemRow-date-right {
  font-size: 14px;
  margin-left: 5px;
}
@media all and (max-width: 991px) {
  .eventItemRow-date-right {
    font-size: 10px;
  }
}
.eventItemRow-date-right .week {
  opacity: 0.5;
}
.eventItemRow-title {
  flex-grow: 1;
  padding-left: 100px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  transition: all 0.32s linear;
}
@media all and (max-width: 991px) {
  .eventItemRow-title {
    padding-left: 80px;
    display: block;
  }
}
@media all and (max-width: 767px) {
  .eventItemRow-title {
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 5px;
  }
}
.eventItemRow-name {
  font-size: 26px;
}
@media all and (max-width: 991px) {
  .eventItemRow-name {
    font-size: 22px;
  }
}
@media all and (max-width: 767px) {
  .eventItemRow-name {
    position: relative;
    font-size: 18px;
    padding-right: 20px;
  }
}
@media all and (max-width: 543px) {
  .eventItemRow-name {
    font-size: 16px;
  }
}
.eventItemRow-name .icon {
  width: 0px;
  height: 0px;
  opacity: 0;
  visibility: hidden;
  fill: #333;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .eventItemRow-name .icon {
    width: 18px;
    height: 14px;
    opacity: 1;
    visibility: visible;
    position: absolute;
    top: 5px;
    right: 0;
  }
}
.eventItemRow-name .sep {
  transition: all 0.1s linear;
}
@media all and (max-width: 991px) {
  .eventItemRow-name .sep {
    display: none;
  }
}
.eventItemRow-place {
  margin-left: 0.5em;
  transition: all 0.1s linear;
}
@media all and (max-width: 991px) {
  .eventItemRow-place {
    margin-left: 0;
  }
}
@media all and (max-width: 767px) {
  .eventItemRow-place {
    font-size: 12px;
  }
}
@media all and (max-width: 767px) {
  .eventItemRow-action {
    display: none;
  }
}
.eventItemRow-bottom {
  line-height: 1.4;
  padding: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.25s linear;
}
@media all and (max-width: 991px) {
  .eventItemRow-bottom {
    font-size: 14px;
  }
}
.eventItemRow-bottom-footer {
  display: none;
  text-align: center;
}
@media all and (max-width: 767px) {
  .eventItemRow-bottom-footer {
    display: block;
    padding-top: 15px;
  }
}
.eventItemRow.is-active .eventItemRow-bottom {
  padding-top: 30px;
  height: auto;
  overflow: auto;
  visibility: visible;
}
@media all and (max-width: 991px) {
  .eventItemRow.is-active .eventItemRow-bottom {
    padding-top: 15px;
  }
}
.eventItemRow-top:hover .eventItemRow-date,
.eventItemRow.is-active .eventItemRow-top .eventItemRow-date {
  opacity: 0;
  left: -90px;
}
@media all and (max-width: 767px) {
  .eventItemRow-top:hover .eventItemRow-date,
  .eventItemRow.is-active .eventItemRow-top .eventItemRow-date {
    left: 0;
    opacity: 1;
  }
}
.eventItemRow-top:hover .eventItemRow-title,
.eventItemRow.is-active .eventItemRow-top .eventItemRow-title {
  padding-left: 0;
}
@media all and (max-width: 767px) {
  .eventItemRow-top:hover .eventItemRow-title,
  .eventItemRow.is-active .eventItemRow-top .eventItemRow-title {
    padding-left: 80px;
  }
}
.eventItemRow-top:hover .eventItemRow-title:hover,
.eventItemRow.is-active .eventItemRow-top .eventItemRow-title:hover {
  color: #ec5e87;
  cursor: pointer;
}
.eventItemRow-top:hover .eventItemRow-name .sep,
.eventItemRow.is-active .eventItemRow-top .eventItemRow-name .sep {
  opacity: 0;
}
.eventItemRow-top:hover .eventItemRow-name .icon,
.eventItemRow.is-active .eventItemRow-top .eventItemRow-name .icon {
  width: 18px;
  height: 14px;
  opacity: 1;
  visibility: visible;
}
.eventItemRow-top:hover .eventItemRow-title:hover .icon,
.eventItemRow.is-active .eventItemRow-top .eventItemRow-title:hover .icon {
  fill: #ec5e87;
}
.eventItemRow-top:hover .eventItemRow-place,
.eventItemRow.is-active .eventItemRow-top .eventItemRow-place {
  opacity: 0;
}
@media all and (max-width: 767px) {
  .eventItemRow-top:hover .eventItemRow-place,
  .eventItemRow.is-active .eventItemRow-top .eventItemRow-place {
    opacity: 1;
  }
}
.eventItemRow.is-active .eventItemRow-name .icon {
  transform: rotateX(180deg);
}
/*
 * --- HELP ACTION ------------------------------
*/
.helpAction {
  margin: 0;
}
.helpAction-item {
  padding: 0;
}
.helpAction-body {
  height: 100%;
  background-color: #213e8c;
  color: #fff;
  text-align: center;
  padding: 50px 15px;
}
.helpAction-title {
  font-size: 42px;
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  margin-bottom: 30px;
}
@media all and (max-width: 991px) {
  .helpAction-title {
    font-size: 32px;
  }
}
@media all and (max-width: 767px) {
  .helpAction-title {
    font-size: 24px;
  }
}
.helpAction-subtitle {
  font-size: 28px;
  font-weight: bold;
  font-family: 'Montserrat', 'Avenir Next Cyr', sans;
  margin-bottom: 15px;
}
@media all and (max-width: 991px) {
  .helpAction-subtitle {
    font-size: 22px;
  }
}
@media all and (max-width: 767px) {
  .helpAction-subtitle {
    font-size: 18px;
  }
}
.helpAction-pic {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media all and (max-width: 543px) {
  .helpAction-pic {
    display: none;
  }
}
.helpActionFull {
  padding: 50px 0;
  text-align: center;
  background-color: #213e8c;
  color: #fff;
}
.helpActionFull-title {
  font-size: 34px;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .helpActionFull-title {
    font-size: 26px;
  }
}
/*
 * --- PAGE ABOUT -------------------------------
*/
.section-team {
  padding: 50px 0;
}
/*
 * --- PAGE CONTACTS ----------------------------
*/
.contactsForm {
  margin-top: 2em;
}
.contactsForm .fgroup {
  margin-bottom: 2em;
}
@media all and (max-width: 543px) {
  .contactsForm .fgroup {
    margin-bottom: 1em;
  }
}
.contactsForm-button {
  text-align: center;
}
.contactsMap {
  height: 450px;
  background-color: #eee;
}
@media all and (max-width: 767px) {
  .contactsMap {
    height: 380px;
  }
}
