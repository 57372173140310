/*!
 --- UI -----------------------------------------
*/
.section
  padding 30px 0

  &--p50
    padding 50px 0

  &-footer
    text-align center
    padding-bottom 15px
    @media xs
      padding-bottom 0


.sectionTitle
  font-family font-montserrat
  font-size 46px
  text-align center
  margin-bottom 30px
  @media md
    font-size 36px
  @media xs
    font-size 26px


.views
  font-size 12px
  display inline-flex
  align-items center

  .icon
    size(18,18)
    fill color
    margin-right 6px


.button
  display inline-flex
  align-items center
  justify-content center
  height 50px
  font-size 16px
  background-color color-pink
  color #fff
  text-align center
  font-family font-montserrat
  padding 5px 25px
  @media sm
    font-size 14px

  &--block
    display flex
    width 100%

  &--slim
    height 38px

  &:hover
    background-color darken(color-pink, 10%)


.fgroup
  position relative

  &--w-icon
    .input
      padding-left 35px

    .icon
      position absolute
      top 50%
      left 10px
      transform translateY(-50%)
      size(15,15)
      fill color-pink

  .error-message
    position absolute
    bottom 2px
    right 2px


.error-message
  font-size 12px
  color color-pink


.input
  width 100%
  border-bottom 1px solid color
  height 34px

  &--ta
    height 130px


.text
  line-height 1.4

  p:not(:last-child)
    margin-bottom 1em

  h1, h2, h3, h4, h5, h6
    color color-pink

    &:not(:last-child)
      margin-bottom 1.4em

  ul, ol
    margin-left 1em

    li
      &:not(:last-child)
        margin-bottom 1em

  &-center
    text-align center


.gallery
  .img
    width 100%
    max-height 600px


.centeredSlider
  position relative

  .swiper-slide
    width auto

  .slide
    max-height 580px

    img
      height 580px
      margin 0 auto
      @media lg
        height 480px
      @media md
        height 380px
      @media sm
        height 280px

  .slider-button
    position absolute
    top 50%
    transform translateY(-50%)
    z-index 2

    &.prev
      left 15px
      @media xs
        left 5px

    &.next
      right 15px
      @media xs
        right 5px


.slider-button
  display block
  size(48,48)
  @media xs
    size(38,38)

  &:hover
    opacity 0.6

  .icon
    size(42,42)
    fill color-blue
    @media xs
      size(32,32)

  &.prev .icon
    transform rotateY(180deg)


.sectionHalf
  @media sm
    padding 0 15px

  &-pic
    height 100%
    bg(top right,cover)
    @media sm
      display none

  &-txt
    padding 2em 0 2em 15px
    max-width 600px
    margin 0 auto
    @media sm
      padding-left 0

    .text
      font-size 18px
      @media lg
        font-size 16px

      h1, h2, h3, h4, h5, h6
        font-size 1.8em
        text-align center
        color color
        margin-bottom 0.5em
        @media sm
          font-size 1.6em

      ul, ol
        li
          &:not(:last-child)
            margin-bottom 0.5em
