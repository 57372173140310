/*!
 --- FOOTER -------------------------------------
*/

.footer
  &-top
    padding 50px 15px

  &-item
    &:not(:last-child)
      @media md
        margin-bottom 50px

  &-right
    text-align center

  &-bottom
    padding 10px 0
    background-color color-blue
    color #fff
    text-align center


.fTitle
  font-size 20px
  font-family font-montserrat
  margin-bottom 15px


.fInfo
  max-width 300px
  margin 0 auto
  @media md
    max-width 100%

  &-title
    text-transform uppercase

  &-txt
    font-size 15px
    line-height 1.4

  &-data
    resetlist()
    margin-top 30px
    font-size 15px

    li
      &:not(:last-child)
        margin-bottom 8px


.subscribeForm
  max-width 300px
  margin 0 auto

  .fgroup
    &:not(:last-child)
      margin-bottom 15px


.fCopyright
  font-size 15px
  @media md
    font-size 14px
  @media xs
    font-size 12px

  span
    @media xs
      display block
      margin 10px 0

  .sep
    display inline-block
    margin 0 0.5em
    @media xs
      display none


.fSitemap
  display inline-block
  text-align left

  &-title
    font-family font-montserrat
    font-size 18px
    margin-bottom 15px
    @media md
      display none

  &-list
    resetlist()
    @media md
      display flex
      flex-wrap wrap
      justify-content center
    @media xs
      display block
      text-align center

    li
      &:not(:last-child)
        margin-bottom 15px
        @media md
          margin-bottom 0

      @media md
        margin 0 15px
      @media xs
        padding 10px 0
        margin 0 5px
