/*!
 * --- PAGE HEADER ------------------------------
*/
.pageHeader
  background-color color-blue
  color #fff
  text-align center
  padding 120px 0 50px
  @media md
    padding-top 100px
  @media sm
    padding-top 120px
    padding-bottom 40px
  @media xs
    padding-top 110px
    padding-bottom 30px

  &-title
    @media xs
      font-size 1.6em
