/*!
 * --- QUOTE ------------------------------------
*/
.quote
  background-color color-blue
  color #fff
  text-align center
  padding 50px 0
  @media sm
    padding 30px 0

  &--pink
    background-color color-pink

  &-body
    max-width 980px

  &-txt
    font-style italic
    font-size 34px
    margin-bottom 10px
    @media md
      font-size 24px
    @media xs
      font-size 18px
      margin-bottom 15px

  &-author
    font-size 18px
    @media md
      font-size 14px
    @media xs
      font-size 12px
